.main{
    display:flex ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
}



.section1 {
    width: 100%;
    min-height: calc(100vh - 3.6rem);
    position: relative;
}


.section1Container{
    width: 100%;
    max-width: 1280px;
    padding: 6.4rem 1.6rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.h1-1{
    color: var(--Primary2);
}
.section1Container .h1-2{
    font-size: 5rem;
    max-width: 46rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
}
.section1Container .h1-2::after{
    content: ".";
    color: var(--Secondary);
}



.section2{
    width: 100%;
    background-color: var(--Primary4);
}
.section2Container{
    width: 100%;
    max-width: 1280px;
    padding: 6.4rem 1.6rem;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.AreaItens{
    display: flex;
    flex-direction: column;
    gap: 6.4rem;
}
.section2Container .imgMask{
    width: 100%;
    max-width: 24rem;
    border: solid 4px var(--Secondary);
    padding: 0;
    margin-top: 15px;
    margin-left: 15px;
    aspect-ratio: 9/11;
}
.section2Container .imgMask img{
    background-color: var(--Black);
    width: 100%;
    height: 100%;
    padding: 0;
    object-position: center;
    object-fit: cover;
    margin-top: -15px;
    margin-left: -15px;
}
.aboutInfos{
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}
.aboutInfos b{
    color: var(--Secondary);
}
.aboutInfos .text{
    line-height: 2.4rem;
}
.aboutInfos .contacts{
    display: flex;
    gap: 1.6rem;
    flex-wrap: wrap;
    margin-top: 12rem;
}
.aboutInfos .contacts a:hover{
    color: var(--Secondary);
}



.section3{
    width: 100%;
}
.section3Container{
    width: 100%;
    max-width: 1280px;
    padding: 6.4rem 1.6rem;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.skillsContainer{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: start;
    justify-content: space-between;
    gap: 1.2rem;
}
.skillsArea{
    width: 100%;
    padding: 1.2rem 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
}
.skillsArea img{
    width: 100%;
    max-width: 8rem;
    background-color: var(--White);
    padding: 1.8rem;
    border-radius: 5px;
    aspect-ratio: 1/1;
    object-fit: contain;
    object-position: center;
    border-radius: 100%;
    z-index: 1;
    box-shadow: 2px 2px 10px var(--Shadow);
    transition: .4s padding ease-in-out;
    &:hover{
        padding: 0;
    }
}
.skillsArea p {
    background-color: var(--Primary4);
    display: block;
    width: 100%;
    padding: 2rem 0.8rem;
    text-align: end;
    border-radius:0px 15px 15px 0px;
    margin-left: -1.6rem;
    font-size: 1.2rem;
}


.section4{
    width: 100%;
    background-color: var(--Primary4);
}
.section4Container{
    width: 100%;
    max-width: 1280px;
    padding: 6.4rem 1.6rem;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;

}
.containerProjects{
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    display: grid;
    grid-template-columns: (1fr);
}
.projectsArea{
    padding: 2.4rem;
    background-color:var(--Primary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    justify-content: space-between;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
}
.projectsArea p{
    font-size: 1.4rem;
    opacity: 0.8;
}
.technologiesArea{
    display: flex;
    width: 100%;
    max-width: 400px;
    margin-top: 2.4rem;
    gap: 0.8rem;
}
.technologiesArea img{
    width: 100%;
    max-width: 32px;
    object-position: center;
    object-fit: contain;
    aspect-ratio: 1/1;
    background-color: var(--White);
    padding: 0.4rem;
}
.projectImage{
    width: 100%;
    aspect-ratio: 16/8;
    object-position: center;
    object-fit: cover;
}
.projectLinks{
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 1.6rem;
}
.projectLinks a{
    font-size: 2.4rem;
    &:hover{
        color: var(--Secondary);
    }
}
.buttonFilterProjects{
    background-color: var(--Secondary);
    color: var(--White);
    width: 12rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: transform 0s ease-in-out;
    &:hover{
        opacity: 0.5;
    }
}




.section5{
    width: 100%;
}
.section5Container{
    width: 100%;
    max-width: 1280px;
    padding: 6.4rem 1.6rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.containerCertificate{
    background-color: var(--Primary4);
    padding: 2.4rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-right: 1.2rem;
    justify-content: space-between;
    overflow: hidden; 
    padding-bottom: 3.6rem;
}
.containerCertificate img{
    width: 100%;
    display: block;
}
.containerCertificate h3{
    font-size: 1.4rem;
    color: var(--Text);
}
.containerCertificate .link{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.containerCertificate .link p {
    color: var(--Secondary);
}
.containerCertificate .link a {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    &:hover{
        color: var(--Secondary);
    }
}


.section6{
    width: 100%;
    background-color: var(--Primary4);
}
.section6Container{
    width: 100%;
    max-width: 1280px;
    padding: 6.4rem 1.6rem;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}
.section6Container p, .section6Container h2 {
    text-align: center;
}
.sendEmail-form{
    display: grid;
    gap: 3.6rem;
    grid-template-columns: 1fr;
    width: 100%;
    margin: auto;
    max-width: 60rem;
}
.labelForm{
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: flex-start;
    width: 100%;
}
.labelForm span{
    padding: 0;
    color: var(--Secondary);
}
.labelForm input, .labelForm textarea{
    width: 100%;
    padding: 1.6rem;
    background-color: transparent;
    border-bottom: solid 1px var(--Secondary2);
    resize: none;
    color: var(--Text);
    &:focus{
        border-bottom: solid 1px var(--Secondary);
    }
}
.labelForm textarea{
    height: 12rem;
    overflow: auto;
}
.sendEmail-form button{
    background-color: var(--Secondary);
    padding: 1.6rem;
    color: var(--White);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing:1px;
    transition: none;
    &:not(:disabled):hover{
        opacity: 0.6;
    }
    &:disabled{
        cursor: not-allowed;
        height: 20px;
        width: 20px;
        margin: auto;
        font-size: 0px;
        border-radius: 100%;
        border-top:solid 2px var(--Secondary) ;
        border-right:solid 2px var(--Secondary) ;
        background-color: transparent;
        animation: spin .6s infinite ease-in;
    }
}



.footer{
    background-color: var(--Secondary);
    width: 100%;
}
.footerContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 8.6rem 1.6rem;
    gap: 3.2rem;
}
.linksFooter{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin: auto;
    max-width: 86rem;
    border-bottom: solid 1px var(--Shadow);
    padding-bottom: 3.6rem;
    align-items: start;
}
.linksFooter .linksContacts{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.linksFooter a{
    color: var(--Black);
    opacity: 0.8;
    padding: 0.4;
    width: fit-content;
    &:hover{
        opacity: 0.4;
    }
}

@media (min-width:768px){
    .containerProjects{
        grid-template-columns: repeat(2,1fr)
    }
    .skillsContainer{
        gap: 3.2rem;
    }
    .skillsArea p {
        font-size: 1.4rem;
    }
}

@media (min-width:1024px){
    .AreaItens{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .skillsContainer{
        grid-template-columns: repeat(4,1fr);
    }

    .containerProjects{
        grid-template-columns: repeat(3,1fr)
    }

    .containerCertificate {
        position: relative;
        overflow: hidden; 
    }
    .containerCertificate:hover::after{
        content: attr(data-description);
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: 1.4rem;
        top: 0;
        left: 0;
        padding: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
        color: white; 
        white-space: pre-wrap; 
        word-wrap: break-word; 
        box-sizing: border-box; 
        letter-spacing: 1px;
        line-height: 2.4rem;
        pointer-events: none; 
        animation: forwards .5s ease-in-out moveAndTransformShape ;
    }
    
    .sendEmail-form{
        grid-template-columns: repeat(2,1fr);
    }
    .sendEmail-form .labelForm:nth-child(3){
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .sendEmail-form button{
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .linksFooter{
        flex-direction: row;
        justify-content: space-between;
    }

}


/* ---------------- animations */
.animateOpenLeft{
    transform: translateX(-20%);
    opacity: 0;
    transition: ease .8s all;
}
.animateOpenLeftRemove{
    transform: translateX(0%);
    opacity: 1;
    transition: ease .8s all;
}
.animateOpenRight{
    transform: translateX(20%);
    opacity: 0;
    transition: ease .8s all;
}
.animateOpenRightRemove{
    transform: translateX(0%);
    opacity: 1;
    transition: ease .8s all;
}
.animateInvisible{
    opacity: 0;
    transition: ease .5s all;
}
.animateInvisibleRemove{
    opacity: 1;
    transition: ease .5s all;
}
.animationFilterProject{
    opacity: 0;
    animation: animateProject 2s forwards;
}


@keyframes animateProject {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes moveAndTransformShape {
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
@keyframes spin{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}