*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  scroll-behavior: smooth;
}


body{
  overflow-x: hidden;
  background-color:var(--Primary);
  color: var(--Text);
  font-family:"Ubuntu", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

html{
  font-size: 62.5%;
}

p, a {
  font-size: 1.6rem;
}

h1{
  font-size: 3.2rem;
}
h2{
  font-size: 2.4rem;
  color: var(--Secondary);
}
h3{
  font-size: 2.0rem;
  color: var(--Secondary);
}
h4{
  font-size: 1.8rem;
}
ul{
  list-style: none;
}
button{
  display: flex;
  align-items: center;
  justify-content: center;
}
button, a {
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
a{
  text-decoration: none;
  color: var(--Text);
}
.removeScroll{
  overflow: hidden;
}
img{
  user-select: none;
}
.animationBackground{
  display: none;
}

::-webkit-scrollbar {
  width: 10px; 
}

::-webkit-scrollbar-track {
  background-color: transparent
}

::-webkit-scrollbar-thumb {
  background-color: var(--Secondary);
  border-radius: 6px;
}

::selection{
  background-color: var(--Secondary);
  color: var(--Primary);
}



























































input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: all 999999999999999999999999999999999999999999999999999999999999s ease-in-out 0s; 
  background-color: transparent !important;
  color: var(--Text);
}