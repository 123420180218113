.headerContainer{
    background-color: var(--Primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    height: 60px;
}
.headerContainer h4{
    font-size: 1.2rem;
    color:var(--Text);
    font-weight: 100;
    opacity: 0.8;
}
.headerContainer button svg{
    font-size: 2.4rem;
    color:var(--Text);
}


.navContainer{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: var(--Primary);
    box-shadow: 2px 2px 10px var(--Shadow);
    width: 100%;
    max-width: 46.0rem;
    z-index: 9999;
    animation: forwards 0.5s ease openNav;
    padding: 1.2rem;
}
.navContainer .close{
    padding: 16px;
}
.navContainer .close svg{
    font-size: 2.4rem;
    color:var(--Text);
}
.navClose{
    animation: forwards 0.5s ease closeNav;
}

.navContainer .ul{
    width: 100%;
    border-top:  solid 1px var(--Primary2);
    border-bottom:  solid 1px var(--Primary2);
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 100%;
    gap: 2.4rem;    
}
.buttonTheme{
    height: 3.6rem;
    width: 9.0rem;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 16px 8px;
    box-shadow: 2px 2px 10px var(--Shadow);
}
.buttonTheme svg{
    font-size: 2rem;
    
    border-radius: 100%;
}
.buttonTheme .dark{
    color:var(--Text)
}
.buttonTheme .light{
    color: rgb(241, 201, 67);
}

.buttonLanguage{
    height: 3.6rem;
    width: 9.0rem;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px;
    box-shadow: 2px 2px 10px var(--Shadow);
    color: var(--Text);
}
.buttonLanguage p{
    font-size: 1.2rem;
}
.buttonLanguage p b{
    color: var(--Secondary);
} 
.buttonLanguage svg{
    font-size: 1.6rem;
    transform-style: preserve-3d;
    animation: language 1s infinite linear; 
}

span{
    padding: 16px;
    width: 100%;
    font-size: 1.4rem;
}

.floatMenu{
    padding: 16px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
}
.floatMenu div{
    background-color: var(--Secondary2);
    width: 3.6rem;
    aspect-ratio: 1/1;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover{
        background-color: var(--Secondary);
    }
}
.floatMenu div svg{
    font-size: 2rem;
    color: var(--Text);
}



@keyframes openNav{
    0%{
        transform: translateX(460px);
    }
    100%{
        transform: translateX(0px);
    }
}
@keyframes closeNav{
    0%{
        transform: translateX(0px);
    }
    100%{
        transform: translateX(460px);
    }
}
